import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, CardContent } from '@mui/material';
import { Form, useTranslate } from 'ra-core';
import { useRedirect } from 'react-admin';
import Settings from '../settings';

export const LoginForm = (props: LoginFormProps) => {
    const { className } = props;
    const translate = useTranslate();
    const redirect = useRedirect();

    const submit = () => {
        redirect(Settings.login_werify_point);
    };

    return (
        <StyledForm
            onSubmit={submit}
            mode="onChange"
            noValidate
            className={className}
        >
            <CardContent className={LoginFormClasses.content}>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                    className={LoginFormClasses.button}
                >
                    {translate('ra.auth.sign_in')}
                </Button>
            </CardContent>
        </StyledForm>
    );
};

const PREFIX = 'RaLoginForm';

export const LoginFormClasses = {
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
    icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${LoginFormClasses.content}`]: {
        width: 300,
    },
    [`& .${LoginFormClasses.button}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${LoginFormClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
}));

export interface LoginFormProps {
    redirectTo?: string;
    className?: string;
}

LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};