import CSVFileInputGeneric from './csv_file_input_generic'; // Asegúrate de que esta es la ruta correcta al componente
import _ from "lodash";
import { useNotify, useRefresh } from 'react-admin';

function CSVFileInputParticipants({ id, setLoading, buttonLabel, disabled, mutationFunction }) {
  const notify = useNotify();
  const refresh = useRefresh();

  const handleFileProcessing = async (results) => {
    const fields = results?.meta?.fields;
    let hasMandatoryFields = _.includes(fields, "address") && _.includes(fields, "firstName") && _.includes(fields, "lastName") && _.includes(fields, "company") && _.includes(fields, "position") && _.includes(fields, "lang");
    if (!hasMandatoryFields) {
      notify(`resources.Participant.errorUpdating`, { type: 'error' });
      return;
    }
    const data = filterDuplicatedEmails(results.data);
    try {
      await mutationFunction(data);
      refresh();
    } catch (e) {
      notify(`resources.AttributeList.errorUpdating`, { type: 'error' });
    }
  };

  return (
    <CSVFileInputGeneric
      id={id}
      setLoading={setLoading}
      buttonLabel={buttonLabel}
      handleFileProcessing={handleFileProcessing}
      additionalDisabledCondition={disabled}
    />
  );
}

const filterDuplicatedEmails = data => {
  const seen = new Set();
  
  return _.filter(data, obj => {
    if (seen.has(obj.address)) {
      return false;
    } else {
      seen.add(obj.address);
      return true;
    }
  });
};

export default CSVFileInputParticipants;