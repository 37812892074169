import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { CircularProgress, Box } from '@mui/material';
import {
    useLogin,
    useNotify,
    useSafeSetState,
} from 'ra-core';
import { useRedirect } from 'react-admin';
import CssBaseline from '@mui/material/CssBaseline';
import { useLocation } from 'react-router-dom';


export const LoginRedirect = () => {
    const [error, setError] = useSafeSetState(null);
    const location = useLocation(); 
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const login = useLogin();
    const notify = useNotify();
    const redirect = useRedirect();

    React.useEffect(() => {
        token && login({token}).catch(() => {
            notify("ra.auth.sign_in_error");
            redirect("/login");
        });
    }, [token])

    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        {error ?
        <Box>Fallo</Box>
        : 
        <CircularProgress/>
        }
    </Box>
}